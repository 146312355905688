<template>
  <div>
    <div class="d-flex align-items-start mb-1" v-for="(review, index) in reviews" :key="index">
      <b-link :to="`/profile/${review.raterId.username}`"
        ><b-avatar size="34" :src="review.raterId.profileImage" class="mt-25 mr-75"
      /></b-link>
      <div class="profile-user-info w-100">
        <div class="d-flex align-items-center justify-content-between">
          <div class="item-rating d-flex align-items-center">
            <ul class="unstyled-list list-inline mb-0">
              <li
                v-for="star in 5"
                :key="`rating${star}`"
                class="ratings-list-item"
                :class="{ 'ml-25': star - 1 }"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[
                    { 'fill-current': star <= review.number },
                    star <= review.number ? 'text-warning' : 'text-muted',
                  ]"
                />
              </li>
            </ul>
            <span class="text-shadow ml-1">{{ review.number.toFixed(2) }}</span>
          </div>

          <b-link
            class="text-body"
            @click="deleteReview(review._id, index)"
            v-if="isMineReview(review.raterId._id)"
          >
            <feather-icon icon="TrashIcon" size="18" class="mr-25" />
          </b-link>
        </div>
        <p class="mb-0">
          <em>{{ review.review }}</em>
        </p>
        <h6 class="mb-0">
          <b-link :to="`/profile/${review.raterId.username}`">{{ review.raterId.username }}</b-link>
          ⸱
          <span class="text-muted">{{ moment(review.createdAt).fromNow(false) }}</span>
        </h6>
      </div>
    </div>
    <div v-if="isLoading" class="text-muted text-center">Loading Reviews..</div>
    <b-button variant="light" v-if="loaded < count" @click="getReviews" block size="sm"
      >Load more</b-button
    >
  </div>
</template>
<script>
import { BCard, BAvatar, BLink, BButton } from "bootstrap-vue";
import moment from "moment";
import axios from "axios";
import store from "@/store";
export default {
  components: {
    BCard,
    BAvatar,
    BLink,
    BButton,
  },
  data() {
    return {
      count: 0,
      loaded: 0,
      isLoading: false,
      reviews: [],
      page: 1,
      perPage: 3,
    };
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props) {},
  methods: {
    moment: function (e) {
      return moment(e).locale(this.$i18n.locale);
    },
    async deleteReview(reviewId, index) {
      try {
        const formData = new FormData();
        formData.append("reviewId", reviewId);
        const res = await axios.delete(`ratings/${reviewId}`);
        this.reviews = this.reviews.filter((x) => {
          return x._id != reviewId;
        });
      } catch (e) {
        alert("Unable to delete, try again later");
      }
    },
    isMineReview(raterId) {
      if (!store.getters["auth/isAuth"]) {
        return false;
      }
      return raterId == store.getters["auth/user"]._id;
    },
    async getReviews() {
      try {
        const formData = new FormData();
        formData.append("userId", this.userId);
        formData.append("page", this.page);
        formData.append("perPage", this.perPage);

        this.isLoading = true;
        const { data } = await axios.post("allReviews", formData);
        this.count = data.totalReviews;
        this.loaded += data.data.length;
        data.data.map((x) => {
          this.reviews.push(x);
        });
        this.isLoading = false;
        this.page += 1;
      } catch (e) {
        this.isLoading = false;
        alert(e.message);
      }
    },
  },
  created() {
    this.getReviews();
  },
};
</script>
