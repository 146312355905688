<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center mb-2">
        <h5 :v-b-modal="`'modal${title}'`" class="mb-0">
          {{ title }} <span>({{ formatNumber(stats.count) || 0 }})</span>
        </h5>
        <b-button
          v-if="stats.showFollowButton"
          @click="stats.followed ? unFollowProfile() : followProfile()"
          :variant="stats.followed ? 'danger' : 'primary'"
          size="sm ml-auto"
          >{{ stats.followed ? "Unfollow" : $t("profile.Follow") }}</b-button
        >
      </div>
      <div v-if="users.length < 1" class="text-muted text-center">
        {{ $t("profile.No Activity yet") }}
      </div>
      <div v-for="(cUser, index) in users" :key="index">
        <div v-if="cUser._id.username" class="d-flex justify-content-start align-items-center mt-1">
          <div class="mr-1">
            <b-link :href="`/profile/${cUser._id.username}`"
              ><b-avatar variant="light" rounded size="40" :src="cUser._id.profileImage"
            /></b-link>
          </div>
          <div class="profile-user-info">
            <h6 class="mb-0">
              <b-link :href="`/profile/${cUser._id.username}`">{{ cUser._id.username }}</b-link>
            </h6>
            <small class="text-muted">{{ $t(`profile.${cUser._id.userType}`) }}</small>
          </div>
        </div>
      </div>
      <b-button
        @click="$bvModal.show(`modal${title}`)"
        block
        class="mb-0 mt-1 text-muted pb-0"
        variant="link"
        v-if="stats.count > 5"
        >View All {{ title }}</b-button
      >
    </b-card>
    <b-modal
      :id="`modal${title}`"
      scrollable
      :title="title"
      cancel-title="Close"
      hide-backdrop
      hide-footer
      class="border"
    >
      <followers-list :dataType="type" :userId="userId" />
    </b-modal>
  </div>
</template>
<script>
import { BCard, BButton, BAvatar, BLink, BModal } from "bootstrap-vue";
import useHelper from "@/helper";
import FollowersList from "./FollowersList.vue";
export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BLink,
    BModal,
    FollowersList,
  },
  data() {
    return {};
  },
  setup() {
    const { formatNumber } = useHelper();
    return {
      formatNumber,
    };
  },
  methods: {
    followProfile() {
      this.$parent.followUnfollowProfile("follow");
    },
    unFollowProfile() {
      this.$parent.followUnfollowProfile("unfollow");
    },
    isThisMyProfile() {
      if (!store.getters["auth/isAuth"]) {
        return false;
      }
      return this.profile.userId == store.getters["auth/user"]._id;
    },
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    stats: {
      type: Object,
      required: false,
      default: {},
    },
    type: {
      type: String,
      required: true,
      default: "followers",
    },
    userId: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>
