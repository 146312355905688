import { ref, watch, computed } from "@vue/composition-api";
import axios from "axios";
import i18n from "@/libs/i18n";
export default function useProfile() {
  const selectedTab = ref("Timeline");

  const getProfileTabs = (profileType) => {
    const types = {
      timeline: { name: "Timeline", label: "Timeline", icon: "ClockIcon" },
      profile: { name: "Profile", label: i18n.t("profile.Profile"), icon: "UserIcon" },
      services: { name: "Services", label: i18n.t("profile.Services"), icon: "ServerIcon" },
      projects: { name: "Projects", label: i18n.t("profile.Projects"), icon: "CameraIcon" },
      ideas: { name: "Ideas", label: i18n.t("profile.Ideas"), icon: "PenToolIcon" },
      products: { name: "Products", label: i18n.t("profile.Products"), icon: "PackageIcon" },
      recommendations: {
        name: "Recommendations",
        label: i18n.t("profile.recommendations"),
        icon: "ShareIcon",
      },
    };

    if (profileType == "broker" || profileType == "advisor") {
      if (profileType == "broker") {
        return [types.profile, types.services, types.recommendations];
      }
      return [types.profile, types.services];
    }
    return [
      types.timeline,
      types.profile,
      types.services,
      types.projects,
      types.ideas,
      types.products,
    ];
  };

  const updateTab = (newTab) => {
    selectedTab.value = newTab;
  };

  /* FREELANCER */
  const freelancerLanguageCols = [
    { key: "name", label: i18n.t("profile.Language"), sortable: false },
    { key: "level", label: i18n.t("profile.Level"), sortable: false },
  ];

  const freelancerSkillCols = [{ key: "name", label: i18n.t("profile.Skill") }];

  const freelancerEducationCols = [
    {
      key: "degree",
      label: i18n.t("profile.Degree"),
    },
    { key: "institute", label: i18n.t("profile.Institute") },
    { key: "location", label: i18n.t("profile.Location") },
    { key: "date", label: "Year", label: i18n.t("profile.Year") },
  ];

  const freelancerCertificationCols = [
    { key: "name", label: i18n.t("profile.Certification") },
    { key: "institute", label: i18n.t("profile.Institute") },
    { key: "date", label: i18n.t("profile.Year") },
  ];

  const freelancerExperienceCols = [
    { key: "company", label: i18n.t("profile.Company") },
    { key: "location", label: i18n.t("profile.Location") },
    { key: "years", label: "Year(s)", label: i18n.t("profile.Years") },
  ];

  /* BROKER */
  const brokerExperienceCols = [
    { key: "company", label: i18n.t("profile.Company") },
    { key: "location", label: i18n.t("profile.Location") },
    { key: "years", label: "Year(s)", label: i18n.t("profile.Years") },
  ];

  const brokerLanguageCols = [
    { key: "name", label: i18n.t("profile.Language"), sortable: false },
    { key: "level", label: i18n.t("profile.Level"), sortable: false },
  ];

  /* 
  GET PROFILES DATA
  */
  const getProfiles = async (userId, type) => {
    try {
      const response = await axios.get(`/userProfiles/${userId}/${type}`);
      const data = response.data;
      return data.data;
    } catch (error) {}
  };

  return {
    getProfileTabs,
    selectedTab,
    updateTab,

    /* Freelancer */
    freelancerLanguageCols,
    freelancerSkillCols,
    freelancerEducationCols,
    freelancerCertificationCols,
    freelancerExperienceCols,

    /* Broker */
    brokerExperienceCols,
    brokerLanguageCols,

    /* functions */
    getProfiles,
  };
}
