<template>
  <div>
    <div v-for="(cUser, index) in users" :key="index">
      <div v-if="cUser._id.username" class="d-flex justify-content-start align-items-center mt-1">
        <div class="mr-1">
          <b-link target="_blank" :href="`/profile/${cUser._id.username}`"
            ><b-avatar variant="light" rounded size="40" :src="cUser._id.profileImage"
          /></b-link>
        </div>
        <div class="profile-user-info">
          <h6 class="mb-0">
            <b-link target="_blank" :href="`/profile/${cUser._id.username}`">{{
              cUser._id.username
            }}</b-link>
          </h6>
          <small class="text-muted">{{ $t(`profile.${cUser._id.userType}`) }}</small>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="text-center text-muted">Loading Contacts...</div>
    <div v-if="loadedUsers < totalUsers">
      <b-button @click="loadContacts()" variant="link" class="text-muted text-center" block
        >Load more</b-button
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BCard, BButton, BAvatar, BLink } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BLink,
  },
  data() {
    return {
      users: [],
      totalUsers: 0,
      loadedUsers: 0,
      perPage: 5,
      currentPage: 0,
      isLoading: false,
    };
  },
  methods: {
    loadContacts: async function () {
      try {
        // manuplate the data variables

        this.isLoading = true;
        this.currentPage += 1;

        const formData = new FormData();
        formData.append("page", this.currentPage);
        formData.append("userId", this.userId);
        formData.append("dataType", this.dataType);
        formData.append("perPage", this.perPage);
        const { data } = await axios.post(`users/followers/list/`, formData);
        const newUsers = this.dataType == "followers" ? data.data.followers : data.data.followings;
        this.loadedUsers += newUsers.length;
        this.totalUsers = data.total;
        this.users = [...this.users, ...newUsers];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.loadContacts();
  },
  props: {
    dataType: {
      type: String,
      required: false,
      default: "followers",
    },
    userId: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
