<template>
  <div id="profileFreelancer">
    <b-card no-body>
      <b-tabs card class="p-0">
        <b-tab :title="$t('profile.Experience')" active no-body>
          <b-table responsive :fields="freelancerExperienceCols" :items="advisor.experiences">
            <template #cell(location)="data">{{ data.item.location.name }}</template>
          </b-table>
        </b-tab>
        <b-tab :title="$t('profile.Previous Project')">
          <b-card-text>{{ advisor.previousProject }}</b-card-text>
        </b-tab>
        <b-tab :title="$t('profile.Skills')" no-body
          ><b-table responsive :items="advisor.skills" :fields="freelancerSkillCols"></b-table
        ></b-tab>
        <b-tab :title="$t('profile.Languages')" no-body
          ><b-table responsive :items="advisor.languages" :fields="freelancerLanguageCols"></b-table
        ></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import moment from "moment";
import useProfile from "./useProfile";
import { BRow, BCol, BCard, BTable, BTab, BTabs } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BTab,
    BTabs,
  },
  props: {
    components: {
      BRow,
      BCol,
      BCard,
      BTable,
    },
    advisor: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const {
      freelancerLanguageCols,
      freelancerSkillCols,
      freelancerEducationCols,
      freelancerCertificationCols,
      freelancerExperienceCols,
    } = useProfile();

    return {
      freelancerLanguageCols,
      freelancerSkillCols,
      freelancerEducationCols,
      freelancerCertificationCols,
      freelancerExperienceCols,
      moment,
    };
  },
};
</script>
<style scoped>
.card.flCard .card-header {
  font-size: 18px;
  font-weight: 500;
}
</style>
<style>
#user-profile .card .card-header {
  padding: 0px;
}
#user-profile .nav-tabs {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.nav-tabs .nav-item {
  border-right: 1px solid #f1f1f1;
}
#user-profile .nav-tabs .nav-link {
  padding: 1rem 1.5rem;
}
.table-responsive {
  margin-bottom: 0px;
}
</style>
