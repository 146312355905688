import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import axios from "axios";
import i18n from "@/libs/i18n";

export default function useTimeline() {
  const profileType = ref("");
  const category = ref("");
  const countries = ref([]);
  const blockchainExchange = ref("");

  const filtersData = ref({});
  const preData = ref({});

  /* 
  Pagination refs
  */
  const totalPosts = ref(0);
  const totalLoaded = ref(0);
  const perPage = ref(5);
  const page = ref(1);
  const isPreloading = ref(false);

  const prepareData = async () => {
    const profileTypes = [{ value: "", text: i18n.t("Timeline.Show All") }];
    const categories = [{ value: "", text: i18n.t("Timeline.Show All") }];
    const countries = [];
    const blockchainExchange = [
      {
        value: "",
        text: i18n.t("Timeline.Show All"),
      },
      {
        value: true,
        text: i18n.t("Timeline.Only Blockchain Exchange"),
      },
      {
        value: false,
        text: i18n.t("Timeline.Except Blockchain Exchange"),
      },
    ];

    /* Profile Types */
    preData.value.profileTypes.map((item) => {
      profileTypes.push({
        value: item.slug,
        text: i18n.locale == "ar" ? item.arabicName : item.name,
      });
    });

    /* Categories */
    preData.value.categories.map((item) => {
      if (profileType.value != "" && profileType.value != item.type) {
        return;
      }
      categories.push({
        value: item._id,
        text: i18n.locale == "ar" ? item.arabicName : item.name,
      });
    });

    /* Countries */
    preData.value.countries.map((item) => {
      countries.push({
        id: item._id,
        label: item.name,
      });
    });

    filtersData.value = {
      profileTypes,
      categories,
      countries,
      blockchainExchange,
    };

    store.dispatch("timeline/setTrendingServices", preData.value.serviceProfiles);
    store.dispatch("timeline/setTrendingProjects", preData.value.projectProfiles);
    store.dispatch("timeline/setTrendingProducts", preData.value.productProfiles);
    store.dispatch("timeline/setTrendingIdeas", preData.value.ideaProfiles);
    store.dispatch("timeline/setWhoToFollow", preData.value.followUsers);
    store.dispatch("timeline/setBookmarks", preData.value.bookmarks);
  };

  const getFiltersData = async () => {
    try {
      isPreloading.value = true;
      const res = await axios.get("timeline/pre");
      preData.value = res.data;
      prepareData();
    } catch (error) {
      console.log(error);
    } finally {
      isPreloading.value = false;
    }
  };

  const loadPosts = async () => {
    const data = new FormData();
    data.append("perPage", perPage.value);
    data.append("page", page.value);
    data.append("profileType", profileType.value);
    data.append("category", profileType.value == "" ? "" : category.value);
    data.append("countries", countries.value.length ? JSON.stringify(countries.value) : "");
    data.append("blockchainExchange", blockchainExchange.value);

    fetchPosts(data).then((response) => {});
  };

  const fetchPosts = (data) => {
    return store.dispatch("timeline/getPostsData", data);
  };

  //loadPosts();

  /* 
  Reformat Data on filters change
  */
  watch([profileType], () => {
    category.value = "";
    prepareData();
  });

  /* watch all the filters and search record */
  watch(
    [profileType, category, countries, blockchainExchange],
    () => {
      page.value = 1;
      loadPosts();
    },
    { deep: true }
  );

  return {
    profileType,
    category,
    countries,
    filtersData,
    blockchainExchange,
    perPage,
    page,
    getFiltersData,
    fetchPosts,
    isPreloading,
  };
}
