<template>
  <b-card>
    <div v-if="highlights || description">
      <h4 v-if="highlights">{{ $t("profile.Highlights") }}</h4>
      <p v-if="highlights">{{ highlights }}</p>
      <hr v-if="highlights" />
    </div>
    <h4 v-if="description">{{ $t(`profile.About ${capatilized(type)}`) }}</h4>
    <p class="mb-0" v-if="description">{{ description }}</p>
  </b-card>
</template>
<script>
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },

  props: {
    highlights: {
      type: String,
      default: "",
      required: false,
    },
    description: {
      type: String,
      default: "",
      required: false,
    },
    type: {
      type: String,
      default: "",
    },
  },

  methods: {
    capatilized: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
