<template>
  <div class="profileIdeas ecommerce-application">
    <h1>{{ $t("profile.Services") }}</h1>
    <div v-if="loading" class="text-center font-lg">
      <b-spinner variant="primary" />
    </div>
    <div v-if="!loading && services.length == 0" class="alert alert-warning p-2 text-center">
      {{ isMyProfile ? $t("profile.serive_not_created_mine") : $t("profile.serive_not_created") }}
    </div>
    <section class="grid-view" v-if="!loading">
      <b-card
        class="ecommerce-card"
        :key="index"
        no-body
        id="postWidth"
        v-for="(service, index) in services"
      >
        <b-card-body>
          <div class="item-img text-center ml-auto mr-auto">
            <b-link :to="`/profile/details/${service._id}`">
              <b-img
                v-if="service.files.length"
                fluid
                class="card-img-top"
                :src="service.files[0].fileUrl"
              />
            </b-link>
          </div>
          <h6 class="item-name">
            <b-link class="text-body" :to="`/profile/details/${service._id}`">
              {{ service.name }}
            </b-link>
            <b-card-text class="item-company">
              <b-link class="ml-25">
                {{ service.saleType }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ service.description }}
          </b-card-text>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{ 'ml-25': star - 1 }"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[
                      { 'fill-current': star <= service.rating },
                      star <= service.rating ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
            <div>
              <h6 class="item-price" v-if="service.price">${{ service.price.priceNumber }}</h6>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </section>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BLink,
  BImg,
} from "bootstrap-vue";
import useProfile from "./useProfile.js";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BCardBody,
    BCardText,
    BLink,
    BImg,
  },
  data() {
    return {
      loading: false,
      services: [],
    };
  },
  setup(props, { emit }) {
    const { getProfiles } = useProfile();

    return {
      getProfiles,
    };
  },
  mounted() {
    this.loading = true;
    this.getProfiles(this.userId, "service").then((data) => {
      this.services = data;
      this.loading = false;
    });
  },
  methods: {},
  props: {
    userId: {
      type: String,
    },
    isMyProfile: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
