<template>
  <div id="postWidth" class="ecommerce-card" no-body>
    <!--  <profile-post :posts="profileData" /> -->
    <div class="text-center" v-if="isLoading">{{ $t("Timeline.Loading") }}...</div>
    <div class="text-center" v-if="!isLoading && !posts.length">
      {{ $t("Timeline.No more data") }}
    </div>
    <div v-if="posts">
      <vue-loadmore :on-loadmore="loadMore" :finished="isFinished">
        <profile-card
          v-for="(post, index) in posts"
          :key="`profile_card_${index}`"
          :data="post"
          @report="reportResource"
          @showReactions="showReactions"
        ></profile-card>
      </vue-loadmore>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import useTimeline from "../timeline/useTimeline";
import { BCard } from "bootstrap-vue";
import ProfileCard from "../timeline/ProfileCard";
export default {
  components: {
    ProfileCard,
    BCard,
  },
  props: {
    userid: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      posts: "timeline/getPosts",
      postsCount: "timeline/getPostsCount",
      isLoading: "timeline/getIsLoading",
      postsLoaded: "timeline/getPostsLoaded",
      isFinished: "timeline/getIsFinished",
    }),
  },
  data() {
    return {
      reportType: "profile",
      reportResourceId: "",
      reportShow: false,
      reactionsProfileId: "",
    };
  },
  methods: {
    ...mapActions({
      fetchPosts: "timeline/getPostsData",
    }),
    reportResource: function (type, resourceId) {
      this.reportType = type;
      this.reportResourceId = resourceId;
      this.$bvModal.show("reportmodal");
    },
    showReactions: function (profileId) {
      this.reactionsProfileId = profileId;
      this.$bvModal.show("reactionsmodal");
    },
  },
  created() {
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
  },
  setup(props, { emit }) {
    const {
      filterOptions,
      profileType,
      category,
      countries,
      blockchainExchange,
      perPage,
      page,
      fetchPosts,
    } = useTimeline();

    const loadPosts = async () => {
      const data = new FormData();
      data.append("perPage", 100);
      data.append("page", page.value);
      data.append("userId", props.userid);
      fetchPosts(data).then((response) => {});
    };

    loadPosts();

    return {
      filterOptions,
      profileType,
      category,
      countries,
      blockchainExchange,
      perPage,
      page,
      fetchPosts,
    };
  },
};
</script>
