<template>
  <div class="pt-1">
    <div
      v-if="message"
      :class="{
        alert: true,
        'alert-danger': messageType == 'danger',
        'alert-success': messageType == 'success',
      }"
    >
      {{ message }}
    </div>
    <b-row>
      <b-col cols="12" md="9">
        <b-form-textarea
          v-model="review"
          :placeholder="$t('profile.Enter your Comments')"
          rows="3"
        ></b-form-textarea>
      </b-col>
      <b-col cols="12" md="3">
        <b-form-select v-model="stars" :options="starOptions" />
        <b-button
          :disabled="!review || !stars || isSaving"
          @click="submitReview"
          class="mt-1"
          block
          variant="primary"
          >{{ $t("profile.Submit") }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import { BRow, BCol, BButton, BFormInput, BFormTextarea, BFormSelect } from "bootstrap-vue";
import axios from "axios";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
  },
  setup() {},
  data() {
    return {
      isSaving: false,
      message: "",
      messageType: "",
      review: "",
      stars: "",
      starOptions: [
        { text: "1 Star - OK", value: 1 },
        { text: "2 Stars - Fine", value: 2 },
        { text: "3 Stars - Good", value: 3 },
        { text: "4 Stars - Very Good", value: 4 },
        { text: "5 Stars - Highly Recommended", value: 5 },
      ],
    };
  },
  props: {
    profile: {
      type: Object,
    },
  },
  methods: {
    async submitReview() {
      if (this.review == "" || this.stars == "") {
        this.messageType = "danger";
        this.message = "Review Text and Rating stars are required to be filled";
      } else {
        this.isSaving = true;
        const formData = new FormData();
        formData.append("userId", this.profile.userId);
        formData.append("number", this.stars);
        formData.append("review", this.review);
        axios
          .post("/ratings", formData)
          .then((res) => {
            this.messageType = "success";
            this.message = "Thank you for writing! review will be published shortly.";
            this.review = "";
            this.stars = "";
            this.isSaving = false;
          })
          .catch((e) => {
            this.isSaving = false;
            this.messageType = "danger";
            this.message = e.response
              ? e.response.data.message
              : "Unable to process your required, try again later";
          });
      }
    },
  },
};
</script>
