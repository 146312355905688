<template>
  <b-card class="card-about">
    <!-- about -->

    <template v-for="(data, key, index) in aboutData">
      <div
        v-if="
          (typeof data.value == 'object' &&
            data.value.length > 0 &&
            !typeof data.value == 'undefined') ||
          data.value != ''
        "
        :key="`about_item_${index}`"
        class="about-item mb-2"
      >
        <h5 class="text-capitalize mb-75">
          {{ data.title }}
        </h5>
        <b-card-text v-if="data.value !== '-1'">
          {{ typeof data.value == "object" ? data.value.join(", ") : data.value }}
        </b-card-text>
        <b-card-text class="text-center" v-if="data.value == '-1'">
          <b-link class="text-muted" to="/pages/pricing">{{ $t("profile.upgrade") }}</b-link>
        </b-card-text>
      </div>
    </template>
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style>
.card-about .about-item:last-child {
  margin-bottom: 0px !important;
}
</style>
