var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto"},[_c('b-card',[_c('button',{staticClass:"btn-bookmark",class:{ 'btn-bookmarked': _vm.bookmarked },on:{"click":_vm.bookmarkPost}},[_c('feather-icon',{attrs:{"icon":"BookmarkIcon","size":"30"}})],1),_c('div',{staticClass:"d-flex justify-content-start align-items-center mb-1 mx-atuo"},[_c('b-link',{attrs:{"href":("/profile/" + (_vm.data.userId.username))}},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":_vm.data.userId.profileImage == 'false' ? null : _vm.data.userId.profileImage,"size":"50"}})],1),_c('div',{staticClass:"profile-user-info"},[_c('h6',{staticClass:"mb-0"},[_c('b-link',{attrs:{"href":("/profile/" + (_vm.data.userId.username))}},[(_vm.data.userId.userType == 'freelancer')?_c('span',[_vm._v(_vm._s(_vm.data.userId.freelancer.fullName))]):_vm._e(),(_vm.data.userId.userType == 'company')?_c('span',[_vm._v(_vm._s(_vm.data.userId.company.name))]):_vm._e(),(_vm.data.userId.userType == 'investor')?_c('span',[_vm._v(_vm._s(_vm.data.userId.investor.shortName))]):_vm._e(),(_vm.data.userId.userType == 'advisor')?_c('span',[_vm._v(_vm._s(_vm.data.userId.advisor.fullName))]):_vm._e(),(_vm.data.userId.userType == 'broker')?_c('span',[_vm._v(_vm._s(_vm.data.userId.broker.fullName))]):_vm._e(),(_vm.data.userId.userType == 'partner')?_c('span',[_vm._v(_vm._s(_vm.data.userId.partner.fullName))]):_vm._e()]),_c('div',{staticClass:"item-rating d-flex align-items-center"},[_c('ul',{staticClass:"unstyled-list list-inline mb-0"},_vm._l((5),function(star){return _c('li',{key:("rating" + star),staticClass:"ratings-list-item",class:{ 'ml-25': star - 1 }},[_c('feather-icon',{class:[
                    { 'fill-current': star <= (_vm.data.userId.ratingNumber || 0) },
                    star <= (_vm.data.userId.ratingNumber || 0) ? 'text-warning' : 'text-muted' ],attrs:{"icon":"StarIcon","size":"16"}})],1)}),0)])],1),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t(("profile." + (_vm.data.userId.userType))))+" ⸱ "+_vm._s(_vm.moment(_vm.data.createdAt).locale(_vm.$i18n.locale).format("LL - h:mm A z")))])])],1),_c('b-card-text',[_c('b-link',{attrs:{"data-to":_vm.getProfileUrl(_vm.data.profileType, _vm.data._id),"to":("/profile/details/" + (_vm.data._id))}},[_c('h4',[_vm._v(_vm._s(_vm.data.name))])]),_c('p',[_vm._v(_vm._s(_vm.data.highlights))])],1),_c('b-link',{attrs:{"data-to":_vm.getProfileUrl(_vm.data.profileType, _vm.data._id),"to":("/profile/details/" + (_vm.data._id))}},[_c('b-img',{staticClass:"mb-25 w-100",attrs:{"fluid":"","rounded":"","src":_vm.getProfileTypeCoverImage(_vm.data.files)
            ? _vm.getProfileTypeCoverImage(_vm.data.files).fileUrl
            : null}})],1),_c('div',{staticClass:"d-flex my-1 align-items-center"},[(_vm.$store.getters['auth/isAuth'] && _vm.$store.getters['auth/user'].userType == 'broker')?_c('div',[_c('b-link',{staticClass:"d-flex align-items-center",class:_vm.shared == true ? 'text-danger' : 'text-primary',attrs:{"size":"sm"},on:{"click":function($event){return _vm.shareOnMyProfile(_vm.data._id)}}},[_c('feather-icon',{attrs:{"icon":_vm.shared == false ? 'ShareIcon' : 'DeleteIcon'}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.shared == false ? _vm.$t("Timeline.ShareOnYourProfile") : _vm.$t("Timeline.RemoveFromYourProfile")))])],1)],1):_vm._e(),_c('div',{staticClass:"ml-auto"},[_c('b-link',{staticClass:"text-muted",on:{"click":_vm.reportThis}},[_vm._v(_vm._s(_vm.$t(("Report.Report this " + (_vm.data.profileType)))))])],1)]),(_vm.data.postVid)?_c('b-embed',{staticClass:"rounded mb-50",attrs:{"type":"iframe","src":_vm.data.postVid,"allowfullscreen":""}}):_vm._e(),_c('b-row',{staticClass:"pb-50 mt-50 border-top pt-1"},[_c('b-col',{staticClass:"d-flex justify-content-between justify-content-sm-start mb-2",attrs:{"sm":"8"}},[_c('b-link',{staticClass:"d-flex align-items-center text-muted text-nowrap"},[_c('feather-icon',{staticClass:"mr-50",class:this.youLiked ? 'text-primary' : 'text-muted',attrs:{"icon":"ThumbsUpIcon","size":"18"},on:{"click":function($event){return _vm.likeOrUnlike(1)}}}),(this.data.totalReactions < 2)?_c('strong',[_vm._v(_vm._s(_vm.kFormatter(this.data.totalReactions)))]):_vm._e(),(this.data.totalReactions > 1)?_c('b-link',{on:{"click":function($event){return _vm.showReactions(_vm.data._id)}}},[_c('strong',[_vm._v(_vm._s(_vm.kFormatter(this.data.totalReactions)))])]):_vm._e()],1)],1),_c('b-col',{staticClass:"d-flex justify-content-between justify-content-sm-end align-items-center mb-2",attrs:{"sm":"4"}},[_c('b-link',{class:{
            'text-nowrap': true,
            'text-muted': _vm.data.totalComments < 1,
            'text-primary': _vm.data.totalComments,
          }},[_c('feather-icon',{staticClass:"profile-icon mr-50",attrs:{"icon":"MessageSquareIcon","size":"18"}}),_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.kFormatter(_vm.data.totalComments)))])],1)],1)],1),_vm._l((_vm.data.comments),function(comment,ind){return _c('div',{key:ind,staticClass:"d-flex align-items-start mb-1"},[_c('b-link',{attrs:{"to":("/profile/" + (comment.userId.username))}},[_c('b-avatar',{staticClass:"mt-25 mr-75",attrs:{"src":comment.userId.profileImage,"size":"34"}})],1),_c('div',{staticClass:"profile-user-info w-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"mb-0"},[_c('b-link',{attrs:{"to":("/profile/" + (comment.userId.username))}},[_vm._v(_vm._s(comment.userId.username))]),_vm._v(" ⸱ "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.moment(comment.createdAt).locale(_vm.$i18n.locale).fromNow(false)))])],1),(
              _vm.$store.getters['auth/isAuth'] &&
              _vm.$store.getters['auth/user']._id != comment.userId._id
            )?_c('b-link',{staticClass:"ml-auto text-sm text-muted",on:{"click":_vm.reportComment}},[_vm._v(_vm._s(_vm.$t("Timeline.Report")))]):_vm._e(),_c('div',{staticClass:"text-body"},[(
                _vm.$store.getters['auth/isAuth'] &&
                _vm.$store.getters['auth/user']._id == comment.userId._id
              )?_c('feather-icon',{staticClass:"mr-25",class:comment.youLiked ? 'profile-likes' : 'profile-icon',attrs:{"icon":"TrashIcon","size":"18"},on:{"click":function($event){return _vm.deleteComment(comment._id)}}}):_vm._e()],1)],1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.filterComment(comment.comment))}})])],1)}),(_vm.loadedComments < _vm.totalComments)?_c('b-button',{staticClass:"mb-1",attrs:{"disabled":_vm.loadingComments,"block":"","variant":"link"},on:{"click":_vm.loadComments}},[_vm._v(_vm._s(_vm.loadingComments ? _vm.$t("Timeline.Loading") : _vm.$t("Timeline.Load more")))]):_vm._e(),(!_vm.$store.getters['auth/isAuth'])?_c('div',{staticClass:"text-center py-1 text-muted"},[_c('b-link',{attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t("Timeline.Login to Comment")))])],1):_vm._e(),(_vm.$store.getters['auth/isAuth'])?_c('div',[_c('b-form-group',[_c('b-input-group',{scopedSlots:_vm._u([(_vm.commentText != '')?{key:"append",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"size":"sm","variant":"primary","disabled":_vm.isLoading},on:{"click":_vm.postComment}},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"30"}})],1)]},proxy:true}:null],null,true)},[_c('b-form-textarea',{attrs:{"rows":"1","placeholder":_vm.$t('Timeline.Add Comment'),"disabled":_vm.isLoading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.postComment.apply(null, arguments)}},model:{value:(_vm.commentText),callback:function ($$v) {_vm.commentText=$$v},expression:"commentText"}})],1)],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }