<template>
  <b-card :title="$t('profile.Recent Activities')">
    <div v-if="activities.length == 0" class="text-center text-muted py-2">
      {{ $t("profile.No Activity yet") }}
    </div>
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item v-for="act in activities" :key="act._id">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6 v-if="act.type == 'added_profile'">
            {{ $t(`profile.Created new ${act.resProfile.profileType}`) }}
          </h6>
          <h6 v-if="act.type == 'made_auction'">{{ $t("profile.Started New Auction") }}</h6>
          <h6 v-if="act.type == 'created_post'">{{ $t("profile.Created Blog Post") }}</h6>
          <small class="text-muted">{{ moment(act.createdAt).fromNow(false) }}</small>
        </div>
        <b-link
          :href="`/profile/details/${act.resProfile._id}`"
          v-if="act.type == 'added_profile'"
          >{{ act.resProfile.name }}</b-link
        >
        <b-link
          :href="`/profile/details/${act.resAuction.profileId}`"
          v-if="act.type == 'made_auction'"
          >{{ act.resAuction.belongFor }}</b-link
        >
        <b-link :href="`/pages/blog/${act.resPost._id}`" v-if="act.type == 'created_post'">{{
          act.resPost.title
        }}</b-link>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import moment from "moment";
import { BCard, BImg, BMedia, BAvatar, BLink } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BLink,
  },
  methods: {
    moment: function (e) {
      return moment(e).locale(this.$i18n.locale);
    },
  },
  props: {
    activities: {
      type: Array,
      default: [],
    },
  },
};
</script>
