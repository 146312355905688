<template>
  <b-row>
    <b-col lg="12" cols="12">
      <b-card body-class="p-0">
        <table class="table table-striped table-md">
          <tr>
            <td>
              <strong>{{ $t("profile.Interested In") }}</strong>
            </td>
            <td class="text-gray">
              {{
                $store.state.locale.locale == "ar"
                  ? company.companyCategory.arabicName
                  : company.companyCategory.name
              }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t("profile.License Number") }}</strong>
            </td>
            <td class="text-gray">{{ company.licenseNumber }}</td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t("profile.Average Monthly Sales") }}</strong>
            </td>
            <td>
              {{ company.avgMonthlySales }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t("profile.Annual Sales") }}</strong>
            </td>
            <td>
              {{ company.annualSales }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t("profile.EBITDA") }}</strong>
            </td>
            <td class="text-gray">{{ company.ebitda }}</td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t("profile.Assets") }}</strong>
            </td>
            <td class="text-gray">{{ company.assets }}</td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t("profile.Legal Entity") }}</strong>
            </td>
            <td class="text-gray">{{ company.companyType }}</td>
          </tr>
        </table>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BCard, BRow, BCol, BLink } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
  },
  props: {
    company: {
      type: Object,
    },
  },
  setup(props, { emit }) {},
};
</script>
