<template>
  <b-modal
    id="reportmodal"
    ref="reportmodal"
    :title="$t(`Report.Report ${type}`)"
    hide-footer
    size="lg"
  >
    <b-card-text>
      <div
        v-if="msg.type && msg.text"
        :class="{
          alert: true,
          'p-1': true,
          'alert-success': msg.type == 'success',
          'alert-danger': msg.type == 'danger',
        }"
      >
        {{ msg.text }}
      </div>
      <b-form-group :label="$t('Report.Reason')" v-if="!isSubmitted">
        <v-select :options="reasons" v-model="reason" />
      </b-form-group>
      <b-form-group :label="$t('Report.Comments')" v-if="!isSubmitted">
        <b-form-textarea v-model="message" rows="3" />
      </b-form-group>
      <b-button
        v-if="!isSubmitted"
        @click="submitComplaint"
        :disabled="!reason || !message || isSaving"
        class="mr-1"
        variant="primary"
        >{{ isSaving ? "Please wait..." : $t("Report.Submit Report") }}</b-button
      >
      <b-button @click="closeReport" :disabled="isSaving">{{ $t("Report.Close") }}</b-button>
    </b-card-text>
  </b-modal>
</template>
<script>
import { ref } from "@vue/composition-api";
import axios from "axios";
import {
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BCardText,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      reasons: [],
      reason: "",
      message: "",
      isSaving: false,
      msg: {
        type: "",
        text: "",
      },
      isSubmitted: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "profile",
      required: true,
    },
    resourceId: {
      type: String,
      default: "",
      required: false,
    },
  },
  created() {
    this.reason = null;
    this.message = "";
    this.loadReasons();
  },
  methods: {
    submitComplaint: async function () {
      try {
        this.isSaving = true;
        const formData = new FormData();
        formData.append("reasonId", this.reason.value);
        formData.append("comments", this.message);
        formData.append("type", this.type);
        formData.append("userId", this.resourceId);
        formData.append("profileId", this.resourceId);
        const { data } = await axios.post("complaints", formData);
        this.isSaving = false;
        this.msg = {
          type: "success",
          text: "Complaint submitted successfully",
        };
        this.isSubmitted = true;
        this.message = "";
        this.reason = null;
      } catch (e) {
        this.isSaving = false;
        this.msg = {
          type: "danger",
          text: e.response.data.error.message,
        };
      }
    },
    loadReasons: async function () {
      try {
        const { data } = await axios.get("complaints/reasonsList");
        this.reasons = data.data.map((item) => {
          return {
            label: this.$i18n.locale == "ar" ? item.reasonArabic || item.reason : item.reason,
            value: item._id,
          };
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    closeReport: function () {
      this.$refs.reportmodal.hide();
    },
  },
  setup() {},
};
</script>
