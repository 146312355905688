<template>
  <div class="mx-auto">
    <b-card>
      <button class="btn-bookmark" :class="{ 'btn-bookmarked': bookmarked }" @click="bookmarkPost">
        <feather-icon icon="BookmarkIcon" size="30" />
      </button>
      <div class="d-flex justify-content-start align-items-center mb-1 mx-atuo">
        <!-- avatar -->
        <b-link :href="`/profile/${data.userId.username}`"
          ><b-avatar
            :src="data.userId.profileImage == 'false' ? null : data.userId.profileImage"
            size="50"
            class="mr-1"
        /></b-link>
        <!--/ avatar -->
        <div class="profile-user-info">
          <h6 class="mb-0">
            <b-link :href="`/profile/${data.userId.username}`">
              <span v-if="data.userId.userType == 'freelancer'">{{
                data.userId.freelancer.fullName
              }}</span>
              <span v-if="data.userId.userType == 'company'">{{ data.userId.company.name }}</span>
              <span v-if="data.userId.userType == 'investor'">{{
                data.userId.investor.shortName
              }}</span>
              <span v-if="data.userId.userType == 'advisor'">{{
                data.userId.advisor.fullName
              }}</span>
              <span v-if="data.userId.userType == 'broker'">{{ data.userId.broker.fullName }}</span>
              <span v-if="data.userId.userType == 'partner'">{{
                data.userId.partner.fullName
              }}</span>
            </b-link>
            <div class="item-rating d-flex align-items-center">
              <ul class="unstyled-list list-inline mb-0">
                <li
                  v-for="star in 5"
                  :key="`rating${star}`"
                  class="ratings-list-item"
                  :class="{ 'ml-25': star - 1 }"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[
                      { 'fill-current': star <= (data.userId.ratingNumber || 0) },
                      star <= (data.userId.ratingNumber || 0) ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
          </h6>
          <small class="text-muted"
            >{{ $t(`profile.${data.userId.userType}`) }} ⸱
            {{ moment(data.createdAt).locale($i18n.locale).format("LL - h:mm A z") }}</small
          >
        </div>
      </div>
      <b-card-text>
        <b-link
          :data-to="getProfileUrl(data.profileType, data._id)"
          :to="`/profile/details/${data._id}`"
          ><h4>{{ data.name }}</h4></b-link
        >
        <p>{{ data.highlights }}</p>
      </b-card-text>

      <!-- post img -->
      <b-link
        :data-to="getProfileUrl(data.profileType, data._id)"
        :to="`/profile/details/${data._id}`"
      >
        <b-img
          fluid
          rounded
          class="mb-25 w-100"
          :src="
            getProfileTypeCoverImage(data.files)
              ? getProfileTypeCoverImage(data.files).fileUrl
              : null
          "
      /></b-link>
      <div class="d-flex my-1 align-items-center">
        <div
          v-if="$store.getters['auth/isAuth'] && $store.getters['auth/user'].userType == 'broker'"
        >
          <b-link
            @click="shareOnMyProfile(data._id)"
            size="sm"
            class="d-flex align-items-center"
            :class="shared == true ? 'text-danger' : 'text-primary'"
          >
            <feather-icon :icon="shared == false ? 'ShareIcon' : 'DeleteIcon'" />
            <span class="ml-1">{{
              shared == false
                ? $t("Timeline.ShareOnYourProfile")
                : $t("Timeline.RemoveFromYourProfile")
            }}</span>
          </b-link>
        </div>
        <div class="ml-auto">
          <b-link class="text-muted" @click="reportThis">{{
            $t(`Report.Report this ${data.profileType}`)
          }}</b-link>
        </div>
      </div>
      <!--/ post img -->
      <!-- post video -->
      <b-embed
        v-if="data.postVid"
        type="iframe"
        :src="data.postVid"
        allowfullscreen
        class="rounded mb-50"
      />
      <!--/ post video -->

      <!-- likes comments  share-->
      <b-row class="pb-50 mt-50 border-top pt-1">
        <b-col sm="8" class="d-flex justify-content-between justify-content-sm-start mb-2">
          <b-link class="d-flex align-items-center text-muted text-nowrap">
            <feather-icon
              icon="ThumbsUpIcon"
              class="mr-50"
              :class="this.youLiked ? 'text-primary' : 'text-muted'"
              size="18"
              @click="likeOrUnlike(1)"
            />
            <strong v-if="this.data.totalReactions < 2">{{
              kFormatter(this.data.totalReactions)
            }}</strong>
            <b-link @click="showReactions(data._id)" v-if="this.data.totalReactions > 1"
              ><strong>{{ kFormatter(this.data.totalReactions) }}</strong></b-link
            >
            <!-- <span v-if="this.data.totalReactions == 0">
              {{ $t("Timeline.be first to like this") }}</span
            >
            <span v-if="this.youLiked && this.data.totalReactions == 1">{{
              $t("Timeline.you liked this")
            }}</span>

            <div @click="showReactions(data._id)">
              <span class="text-muted" v-if="this.youLiked && this.data.totalReactions > 1"
                >{{ $t("Timeline.you and") }} {{ kFormatter(data.totalReactions - 1) }}
                {{ $t("Timeline.x people like this") }}</span
              >
              <span class="text-muted" v-if="this.youLiked == false && this.data.totalReactions > 0"
                >{{ kFormatter(this.data.totalReactions) }}
                {{ $t("Timeline.x people like this") }}</span
              >
            </div> -->
          </b-link>
        </b-col>
        <b-col
          sm="4"
          class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
        >
          <b-link
            :class="{
              'text-nowrap': true,
              'text-muted': data.totalComments < 1,
              'text-primary': data.totalComments,
            }"
          >
            <feather-icon icon="MessageSquareIcon" size="18" class="profile-icon mr-50" />
            <span class="mr-1">{{ kFormatter(data.totalComments) }}</span>
          </b-link>
        </b-col>
      </b-row>
      <!--/ likes comments  share-->

      <!-- comments -->
      <div v-for="(comment, ind) in data.comments" :key="ind" class="d-flex align-items-start mb-1">
        <b-link :to="`/profile/${comment.userId.username}`"
          ><b-avatar :src="comment.userId.profileImage" size="34" class="mt-25 mr-75"
        /></b-link>
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">
              <b-link :to="`/profile/${comment.userId.username}`">{{
                comment.userId.username
              }}</b-link>
              ⸱
              <span class="text-muted">{{
                moment(comment.createdAt).locale($i18n.locale).fromNow(false)
              }}</span>
            </h6>
            <b-link
              v-if="
                $store.getters['auth/isAuth'] &&
                $store.getters['auth/user']._id != comment.userId._id
              "
              class="ml-auto text-sm text-muted"
              @click="reportComment"
              >{{ $t("Timeline.Report") }}</b-link
            >

            <div class="text-body">
              <feather-icon
                v-if="
                  $store.getters['auth/isAuth'] &&
                  $store.getters['auth/user']._id == comment.userId._id
                "
                icon="TrashIcon"
                size="18"
                class="mr-25"
                :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                @click="deleteComment(comment._id)"
              />
              <!--  <span class="text-muted align-middle">{{ comment.commentsLikes }}</span> -->
            </div>
          </div>
          <p v-html="filterComment(comment.comment)"></p>
        </div>
      </div>
      <b-button
        :disabled="loadingComments"
        class="mb-1"
        v-if="loadedComments < totalComments"
        block
        variant="link"
        @click="loadComments"
        >{{ loadingComments ? $t("Timeline.Loading") : $t("Timeline.Load more") }}</b-button
      >
      <!--/ comments -->

      <!-- comment box -->
      <div v-if="!$store.getters['auth/isAuth']" class="text-center py-1 text-muted">
        <b-link to="/login">{{ $t("Timeline.Login to Comment") }}</b-link>
      </div>
      <div v-if="$store.getters['auth/isAuth']">
        <b-form-group>
          <b-input-group>
            <b-form-textarea
              v-model="commentText"
              rows="1"
              :placeholder="$t('Timeline.Add Comment')"
              :disabled="isLoading"
              @keyup.enter="postComment"
            />
            <template #append v-if="commentText != ''">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="postComment"
                size="sm"
                variant="primary"
                :disabled="isLoading"
                class="ml-1"
              >
                <feather-icon icon="SendIcon" size="30" />
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
        <!--/ comment box -->
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BFormInput,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BInputGroup,
} from "bootstrap-vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import useHelper from "@/helper";
import moment from "moment";
import axios from "axios";
export default {
  components: {
    BAvatar,
    BInputGroup,
    BCard,
    BFormInput,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      commentText: "",
      youLiked: false,
      isLoading: false,

      totalComments: 0,
      loadedComments: 0,
      pageComments: 1,
      loadingComments: false,
      bookmarked: false,
      reported: false,
      shared: false,
    };
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  methods: {
    kFormatter,
    moment: function (input) {
      return moment(input);
    },

    shareOnMyProfile: async function (profileId) {
      this.$swal({
        text: this.shared
          ? this.$t("Timeline.AreYourSureDeleteShare")
          : this.$t("Timeline.AreYouSureShare"),
        showCancelButton: true,
        confirmButtonText: this.$t("Timeline.Yes"),
        cancelButtonText: this.$t("Timeline.NoCancel"),
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            this.shared = !this.shared;
            const formData = new FormData();
            formData.append("profileId", profileId);
            const data = axios.post("/profiles/share", formData);
          } catch (e) {
            this.$swal({
              icon: "danger",
              title: "ERROR",
              text: e.body.message || e.message,
            });
          }
        }
      });
    },
    reportThis: function () {
      this.$emit("report", "profile", this.data._id);
    },

    showReactions: function (id) {
      this.$emit("showReactions", id);
    },

    reportComment(commentId) {
      /* if (prompt("Enter the reason to report the comment")) {
        this.reported = true;
        alert("Thank you! we will review your request and will reach you");
      } */
      this.$emit("report", "comment", commentId);
    },

    filterComment(text) {
      return text.replace("\n", "<br />");
    },
    likeOrUnlike: async function (type) {
      try {
        const userData = new FormData();
        userData.append("profileId", this.data._id);
        userData.append("type", type);

        if (this.youLiked == true) {
          this.youLiked = false;
          this.data.totalReactions -= 1;
        } else {
          this.youLiked = true;
          this.data.totalReactions += 1;
        }
        const res = await axios.post("profileReacts", userData);
      } catch (e) {
        //alert(e.message);
      }
    },
    deleteComment: async function (commentId) {
      try {
        if (confirm("Are you sure you want to delete this comment ?")) {
          const userData = new FormData();
          userData.append("profileId", this.data._id);
          const res = await axios.delete(`comments/${commentId}`, { data: userData });
          const tempComments = this.data.comments.filter((x) => x._id != commentId);
          this.data.comments = tempComments;
          this.data.totalComments -= 1;
        }
      } catch (error) {}
    },
    postComment: async function () {
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("profileId", this.data._id);
        formData.append("comment", this.commentText);
        const res = await axios.post("comments", formData);
        this.data.totalComments += 1;

        const tempComments = this.data.comments;
        tempComments.push({
          _id: res.data.data._id,
          comment: this.commentText,
          createdAt: res.data.data.createdAt,
          userId: {
            _id: res.data.data.userId._id,
            username: res.data.data.userId.username,
            profileImage: res.data.data.userId.userImage,
          },
        });
        this.data.comments = tempComments;

        this.commentText = "";
        this.commentText.focus();
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    loadComments: async function () {
      try {
        this.loadingComments = true;
        const formData = new FormData();
        formData.append("profileId", this.data._id);
        formData.append("page", this.pageComments);
        formData.append("perPage", 3);
        const res = await axios.post("timeline/comments", formData);
        if (res.data.length) {
          this.loadedComments += res.data.length;
          this.data.comments = this.data.comments.concat(res.data);
          if (this.loadedComments < this.totalComments) {
            this.pageComments += 1;
          }
        } else {
          this.loadedComments = this.totalComments;
        }
        this.loadingComments = false;
      } catch (e) {
        alert("Unable to load Comments please try again later");
        this.loadingComments = false;
      }
    },

    bookmarkPost: async function () {
      try {
        const formData = new FormData();
        formData.append("profileId", this.data._id);
        const res = await axios.post("bookmarks", formData);
        this.bookmarked = !this.bookmarked;
      } catch (e) {
        alert("Unable to bookmark this post please try again later");
      }
    },
  },
  setup(props) {
    const { getProfileTypeCoverImage, getProfileUrl } = useHelper();

    return {
      getProfileTypeCoverImage,
      getProfileUrl,
    };
  },
  async created() {
    // check if user bookmarked this post, we will mark as the bookmarked so it will be highlighted
    if (
      store.getters["auth/isAuth"] &&
      store.getters["auth/user"] &&
      store.getters["timeline/getBookmarks"]
    ) {
      const currentProfileId = this.data._id;

      this.bookmarked = store.getters["timeline/getBookmarks"].includes(currentProfileId)
        ? true
        : false;
    }
  },
  mounted() {
    this.totalComments = this.data.totalComments;
    this.loadedComments = this.data.comments.length;
    this.pageComments = this.data.totalComments > 0 ? 2 : 1;

    // checkif user logged in
    this.youLiked = false;
    if (store.getters["auth/isAuth"] && store.getters["auth/user"]) {
      // Check if user liked this post
      if (this.data.reactings.length) {
        const isUserLiked = this.data.reactings.find((user) => {
          return user.react.userId == store.getters["auth/user"]._id;
        });
        this.youLiked = isUserLiked ? true : false;
      }
      // check if current user shared this profile
      if (store.getters["auth/user"].sharedProfiles.find((e) => e._id == this.data._id)) {
        this.shared = true;
      }
    }
  },
};
</script>
<style scoped>
.btn-bookmark {
  position: absolute;
  right: 10px;
  top: 0px;
  border: none;
  padding: 10px 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 0.3s linear all;
}
.btn-bookmark svg {
  transition: 0.2s linear all;
}
.btn-bookmark.btn-bookmarked svg {
  fill: #ffc845;
  stroke: #d09d23;
}
</style>
