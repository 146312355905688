<template>
  <!-- :img-src="headerData.coverImg || false" -->
  <b-card
    :class="{ 'profile-header': true, 'mb-2': true, 'no-cover': headerData.coverImg != false }"
    :img-src="headerData.coverImg || ''"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <div class="coverPlaceholder" v-if="headerData.coverImg == false"></div>
    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div
          class="profile-img"
          :style="{
            backgroundImage: `url(${headerData.avatar})`,
            backgroundSize: 'cover',
          }"
        ></div>
        <!-- profile title -->
        <div class="profile-title ml-3">
          <div class="d-flex align-items-center">
            <h2 class="text-white">{{ headerData.name }}</h2>
            <img class="profileBadge" v-if="headerData.badge" :src="headerData.badge" height="25" />
          </div>
          <p class="text-white">@{{ headerData.username }} • {{ headerData.designation }}</p>
          <div class="item-rating d-flex align-items-center">
            <ul class="unstyled-list list-inline mb-0">
              <li
                v-for="star in 5"
                :key="`rating${star}`"
                class="ratings-list-item"
                :class="{ 'ml-25': star - 1 }"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[
                    { 'fill-current': star <= headerData.rating },
                    star <= headerData.rating ? 'text-warning' : 'text-muted',
                  ]"
                />
              </li>
            </ul>
            <span class="text-white text-shadow ml-1">{{ headerData.rating.toFixed(2) }}</span>
          </div>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar toggleable="md" type="light" class="p-0">
        <!-- toggle button -->
        <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
          <feather-icon icon="AlignJustifyIcon" size="21" />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse id="nav-text-collapse" is-nav>
          <b-tabs class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
            <template #tabs-start>
              <b-nav-item
                v-for="(item, index) in tabs"
                role="presentation"
                class="font-weight-bold"
                :active="item.name == selectedTab"
                :key="`profileHeaderTabs${index}`"
                @click="changeTab(item.name)"
              >
                <feather-icon :icon="item.icon" class="mr-1" />
                <span class="">{{ item.label }}</span>
                <!-- <feather-icon :icon="item.icon" class="d-block d-md-none" /> -->
              </b-nav-item>
            </template>
          </b-tabs>
          <div class="social-icons ml-auto" v-if="headerData.socialMedia">
            <a
              v-if="headerData.socialMedia.facebook"
              :href="headerData.socialMedia.facebook"
              target="_blank"
              class="btn btn-facebook ml-1"
            >
              <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a
              v-if="headerData.socialMedia.twitter"
              :href="headerData.socialMedia.twitter"
              target="_blank"
              class="btn btn-twitter ml-1"
            >
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a
              v-if="headerData.socialMedia.linkedIn"
              :href="headerData.socialMedia.linkedIn"
              target="_blank"
              class="btn btn-linkedin ml-1"
            >
              <i class="fa-brands fa-linkedin"></i>
            </a>
            <a
              v-if="headerData.socialMedia.other"
              :href="headerData.socialMedia.other"
              target="_blank"
              class="btn btn-secondary ml-1"
            >
              <i class="fa-brands fa-at"></i>
            </a>
          </div>
        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const changeTab = (newTab) => {
      emit("update-tab", newTab);
    };
    return {
      changeTab,
    };
  },
  data() {
    return {
      profileTabs: [
        {
          name: "Profile",
          label: this.$t("profile.Profile"),
          icon: "UserIcon",
        },
        {
          name: "Services",
          label: this.$t("profile.Services"),
          icon: "ServerIcon",
        },
        {
          name: "Projects",
          label: this.$t("profile.Projects"),
          icon: "CameraIcon",
        },
        {
          name: "Ideas",
          label: this.$t("profile.Ideas"),
          icon: "PenToolIcon",
        },
        {
          name: "Products",
          label: this.$t("profile.Products"),
          icon: "PackageIcon",
        },
      ],
    };
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    tabs: {
      type: Array,
      default: [],
      required: true,
    },
    selectedTab: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.profileBadge {
  background-color: rgb(255 255 255);
  padding: 2px 1px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left: 10px;
}
#user-profile .profile-header .profile-img-container {
  bottom: 2rem;
}
.coverPlaceholder {
  background-color: #376ce2;
  min-height: 200px;
}
.profile-header.no-cover {
  position: relative;
}
.profile-header.no-cover:after {
  position: absolute;
  content: " ";
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 300px;
  background-color: red;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}
.profile-header.no-cover .card-body {
  z-index: 99999;
}

@media (min-width: 768px) {
  .profile-header-nav .profile-tabs {
    margin-left: 0px;
    width: 60%;
  }
}
@media (max-width: 768px) {
  .social-icons {
    margin-top: 20px;
    border-top: 1px solid #e1e1e1;
    padding: 20px;
  }
}
</style>
