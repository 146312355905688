<template>
  <div>
    <Report type="user" :resource-id="profile.userId" />
    <Header class="publicProfilePage" v-if="!isThisMyProfile()" />
    <div class="container">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <b-skeleton height="200px"></b-skeleton>
          <b-skeleton height="50px"></b-skeleton>
          <b-row>
            <b-col lg="3" cols="12" order="2" order-lg="1">
              <b-skeleton height="35px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
              <b-skeleton height="250px"></b-skeleton>
              <b-skeleton height="100px"></b-skeleton>
              <b-skeleton height="100px"></b-skeleton>
              <b-skeleton height="100px"></b-skeleton>
            </b-col>
            <b-col lg="9" cols="12" order="1" order-lg="2">
              <b-skeleton height="200px"></b-skeleton>

              <b-skeleton-table columns="2" rows="5"> </b-skeleton-table>
              <b-skeleton height="150px"></b-skeleton>
              <b-skeleton height="150px"></b-skeleton>
            </b-col>
          </b-row>
        </template>
      </b-skeleton-wrapper>
      <div v-if="profileError" class="alert alert-danger p-2 text-center">{{ profileError }}</div>
      <div v-if="Object.keys(profile).length" id="user-profile">
        <profile-header
          :tabs="getProfileTabs(profile.type)"
          :selectedTab="selectedTab"
          :header-data="profile.header"
          @update-tab="updateTab"
        />
        <!-- profile info  -->
        <section id="profile-info">
          <b-row>
            <!-- about suggested page and twitter feed -->
            <b-col lg="3" cols="12" order="2" order-lg="1">
              <b-button
                :disabled="chatSessionCreating || loggedInUserType == 'Partner'"
                @click="startChatSession"
                variant="success"
                block
                class="mb-1"
                v-if="loggedInUserType != 'Partner' && !isThisMyProfile()"
              >
                <div v-if="!chatSessionCreating">
                  <feather-icon icon="MessageSquareIcon" />
                  {{ $t("profile.Chat with this user") }}
                </div>
                <span v-if="chatSessionCreating">Creating Chat session...</span>
              </b-button>

              <b-button v-if="!isThisMyProfile()" v-b-modal.reportmodal block class="mb-1">{{
                $t("profile.Report this user")
              }}</b-button>

              <profile-about :about-data="profile.about" />

              <ProfileFollow
                :title="$t('profile.Followers')"
                :stats="profile.followersStats"
                :users="profile.followers"
                :user-id="profile.userId"
                type="followers"
              />
              <ProfileFollow
                :title="$t('profile.Following')"
                type="followings"
                :stats="profile.followingStats"
                :users="profile.following"
                :user-id="profile.userId"
              />
              <b-card body-class="p-0" v-if="profile.industries">
                <div class="card-header p-2">
                  <strong>{{ $t("profile.Industries") }}</strong>
                </div>
                <table class="table table-striped">
                  <tr v-for="(ind, index) in profile.industries" :key="`ind${index}`">
                    <td>
                      <b-link href="">{{ ind._id ? ind.name : "" }}</b-link>
                    </td>
                  </tr>
                </table>
              </b-card>
            </b-col>

            <!--/ about suggested page and twitter feed -->

            <!-- post -->
            <b-col lg="9" cols="12" order="1" order-lg="2">
              <profile-timeline :userid="userid" v-if="selectedTab == 'Timeline'" />

              <div id="profile" v-if="selectedTab == 'Profile'">
                <profile-overview
                  :description="profile[profile.type].description || ''"
                  :highlights="profile[profile.type].highlights"
                  :type="profile.type"
                />

                <profile-activities :activities="activities" />

                <profile-company v-if="profile.type == 'company'" :company="profile.company" />
                <profile-freelancer
                  v-if="profile.type == 'freelancer'"
                  :freelancer="profile.freelancer"
                />
                <profile-investor v-if="profile.type == 'investor'" :investor="profile.investor" />
                <profile-broker v-if="profile.type == 'broker'" :broker="profile.broker" />
                <profile-advisor v-if="profile.type == 'advisor'" :advisor="profile.advisor" />
              </div>

              <profile-services
                :user-id="profile.userId"
                v-if="selectedTab == 'Services'"
                :is-my-profile="isThisMyProfile()"
              />
              <profile-ideas
                :user-id="profile.userId"
                v-if="selectedTab == 'Ideas'"
                :is-my-profile="isThisMyProfile()"
              />
              <profile-products
                :user-id="profile.userId"
                v-if="selectedTab == 'Products'"
                :is-my-profile="isThisMyProfile()"
              />
              <profile-projects
                :user-id="profile.userId"
                v-if="selectedTab == 'Projects'"
                :is-my-profile="isThisMyProfile()"
              />

              <profile-recommendations
                :user-id="profile.userId"
                v-if="selectedTab == 'Recommendations'"
              />

              <!-- START REVIEWS -->
              <b-card v-if="selectedTab == 'Profile'">
                <b-card-title class="d-flex">
                  <span>{{ $t("profile.Reviews") }} ({{ profile.reviewsCount }})</span>
                </b-card-title>
                <b-card-body>
                  <reviews :userId="profile.userId" />
                </b-card-body>
                <div v-if="isUserLoggedIn && !profile.reviewExist && !isThisMyProfile()">
                  <b-button block @click="toggleRatingForm" class="mt-1" variant="light">{{
                    $t("profile.Write a Review")
                  }}</b-button>
                  <rating-form :profile="profile" v-if="showRatingForm"></rating-form>
                </div>
              </b-card>
              <!-- END REVIEWS -->
            </b-col>
            <!-- post -->
          </b-row>
        </section>
        <!--/ profile info  -->
      </div>
    </div>
    <Footer v-if="!isThisMyProfile()" />
  </div>
</template>

<script>
import axios from "axios";
import useProfile from "./useProfile";
import Report from "../Report";

import {
  BRow,
  BCol,
  BCard,
  VBModal,
  BLink,
  BCardTitle,
  BButton,
  BCardBody,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonImage,
  BSkeletonIcon,
  BSkeletonTable,
} from "bootstrap-vue";
import store from "@/store";
import moment from "moment";

import ProfileHeader from "./ProfileHeader.vue";
import ProfileAbout from "./ProfileAbout.vue";
import RatingForm from "./RatingForm.vue";

import ProfileActivities from "./ProfileActivities.vue";
import ProfileOverview from "./ProfileOverview.vue";

import ProfileFollow from "./ProfileFollow.vue";

/* UserTypes */
import ProfileCompany from "./ProfileCompany.vue";
import ProfileFreelancer from "./ProfileFreelancer.vue";
import ProfileInvestor from "./ProfileInvestor.vue";
import ProfileBroker from "./ProfileBroker.vue";
import ProfileAdvisor from "./ProfileAdvisor.vue";
import ProfileTimeline from "./ProfileTimeline.vue";

/*
  Services
*/
import ProfileServices from "./ProfileServices.vue";
import ProfileIdeas from "./ProfileIdeas.vue";
import ProfileProjects from "./ProfileProjects.vue";
import ProfileProducts from "./ProfileProducts.vue";
import ProfileRecommendations from "./ProfileRecommendations.vue";
import Reviews from "./Reviews.vue";

/* Header/Footer */
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { mapActions, mapGetters } from "vuex";

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BButton,
    BLink,
    BRow,
    BCol,
    ProfileHeader,
    ProfileAbout,
    ProfileFollow,
    ProfileTimeline,

    ProfileCompany,
    ProfileFreelancer,
    ProfileInvestor,
    ProfileBroker,
    ProfileAdvisor,

    ProfileServices,
    ProfileIdeas,
    ProfileProjects,
    ProfileProducts,
    BCardTitle,
    RatingForm,
    Header,
    Footer,
    Reviews,
    BCardBody,
    Report,

    ProfileOverview,
    ProfileActivities,
    ProfileRecommendations,

    BSkeletonWrapper,
    BSkeleton,
    BSkeletonImage,
    BSkeletonIcon,
    BSkeletonTable,
  },
  setup() {
    const { getProfileTabs, selectedTab, updateTab } = useProfile();

    return {
      selectedTab,
      updateTab,
      getProfileTabs,
    };
  },
  data() {
    return {
      profile: {},
      activities: [],
      showRatingForm: false,
      chatSessionCreating: false,
      profileError: "",
      loggedInUserType: null,
      isLoading: false,
      userid: "",
    };
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: "auth/isAuth",
    }),
  },
  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "static" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });

    this.getProfileData();
    this.loggedInUserType = store.getters["auth/getRole"];
  },
  methods: {
    async startChatSession() {
      try {
        const formData = new FormData();
        formData.append("chatType", "private");
        formData.append("receiverId", this.profile.userId);
        const res = await axios.post("/chat/sessions", formData);

        this.$router.replace("/inbox/" + res.data.data._id);
      } catch (e) {
        alert(e.response.data.message || e.message);
      }
    },

    toggleRatingForm() {
      this.showRatingForm = !this.showRatingForm;
    },
    isThisMyProfile() {
      if (!store.getters["auth/isAuth"]) {
        return false;
      }
      return this.profile.userId == store.getters["auth/user"]._id;
    },

    followUnfollowProfile: async function (action) {
      try {
        const userId = this.profile.userId;
        const response = await axios.post(`users/${userId}/${action}`);
        if (action == "follow") this.profile.followersStats.followed = true;
        if (action == "unfollow") this.profile.followersStats.followed = false;
      } catch (error) {
        alert("Sorry! Unable to follow the user");
      }
    },

    getProfileData: async function () {
      try {
        this.isLoading = true;
        const username = this.$route.params.username;
        const { data } = await axios.post(`/users/${username}`);
        const user = data.data;

        this.userid = user._id;

        this.isLoading = false;
        this.profile = data._id;

        //show teh sidebar and default header if user  logged in
        const currentUserId = store.getters["auth/isAuth"] ? store.getters["auth/user"]._id : false;

        this.activities = user.activities || [];

        if (currentUserId !== user._id) {
          store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
          store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
          store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
        }
        /* ====== PREPARING HEADER DATA ===== */
        const headerData = {
          coverImg: user.coverImage == "false" ? false : user.coverImage,
          avatar:
            user.profileImage == "false"
              ? "https://res.cloudinary.com/dk7mx4do7/image/upload/v1655191285/avatar-1577909_1280_aezuqt.webp"
              : user.profileImage,
          username: user.username,
          name: user.userType || user.username,
          designation: user.userType ? this.$t(`profile.${user.userType}`) : user.role,
          socialMedia: {},
          rating: user.ratingNumber,
          badge: user.activePlanId?.badge,
        };
        let industries = [];
        const aboutData = {
          brokerType: {
            title: this.$t("profile.brokerType"),
            value: "",
          },
          gender: {
            title: this.$t("FreelancerForm.Gender"),
            value: "",
          },
          email: {
            title: this.$t("profile.Email"),
            value: "",
          },
          phoneNumber: {
            title: this.$t("profile.Phone Number"),
            value: "",
          },
          location: {
            title: this.$t("profile.Location"),
            value: "",
          },
          locations: {
            title: this.$t("profile.Location"),
            value: [],
          },
          interestedLocations: {
            title: this.$t("profile.Interested Locations"),
            value: [],
          },
          dateJoined: {
            title: this.$t("profile.Member Since"),
            value: moment(user.createdAt).format(store.state.app.dateFormat),
          },
          licenseDetails: {
            title: this.$t("profile.License Details"),
            value: "",
          },
        };

        /* FORMAT THE COMPANY PROFILE */
        if (user.userType == "company") {
          // header
          headerData.name = user.company.legalName;
          headerData.designation = "Company";
          headerData.socialMedia = user.company.socialMedia;
          // about
          aboutData.email.value = user.company.companyEmail;
          aboutData.location.value = user.company.address.country.name;
          aboutData.phoneNumber.value = user.company.companyPhone.number;
          // Industries
          industries = user.company.industries;
        }

        /* FORMAT THE  FREELANCER PROFILE  */
        if (user.userType == "freelancer") {
          // freelancer
          headerData.name = user.freelancer.fullName;
          headerData.designation = this.$t("profile.freelancer");
          headerData.socialMedia = user.freelancer.socialMedia;
          // About Data
          aboutData.email.value = user.freelancer.emailAddress;
          aboutData.phoneNumber.value = user.freelancer.freelancerPhone.phoneNumber;

          /// Industries
          industries = user.freelancer.industries;
        }

        if (user.userType == "investor") {
          headerData.name = user.investor.companyName;
          headerData.designation = user.investor.designation;
          headerData.socialMedia = user.investor.socialMedia;
          // About Dataß
          aboutData.email.value = user.investor.emailAddress;
          aboutData.phoneNumber.value = user.investor.phone.phoneNumber;
          industries = user.investor.industries;

          aboutData.locations.value = user.investor.address.map((x) => {
            return x.name;
          });
          aboutData.interestedLocations.value = user.investor.interstedLocations.map((x) => {
            return x.name;
          });
        }

        if (user.userType == "broker") {
          headerData.name = user.broker.fullName;
          aboutData.brokerType.value = user.broker.brokerType;
          aboutData.gender.value = user.broker.gender
            ? this.$t(`profile.gender_${user.broker.gender}`)
            : "";
          aboutData.licenseDetails.value = user.broker.licenseDetails;
          aboutData.locations.value = user.broker.address.map((x) => {
            return x.name;
          });

          aboutData.email.value = user.broker.emailAddress;
          aboutData.phoneNumber.value = user.broker.brokerPhone.phoneNumber;
          industries = user.broker.industries;
        }

        if (user.userType == "advisor") {
          headerData.name = user.advisor.fullName;
          aboutData.gender.value = this.$t(`profile.gender_${user.advisor.gender}`);
          aboutData.locations.value = user.advisor.address.map((x) => {
            return x.name;
          });
          aboutData.email.value = user.advisor.emailAddress;
          aboutData.phoneNumber.value = user.advisor.advisorPhone.phoneNumber;

          industries = user.advisor.industries;
        }

        this.profile = {
          userId: user._id,
          type: user.userType,
          role: user.role,
          header: headerData,
          about: aboutData,
          company: user.company,
          freelancer: user.freelancer,
          investor: user.investor,
          partner: user.partner,
          advisor: user.advisor,
          broker: user.broker,
          followers: user.followers || [],
          following: user.followings || [],

          industries: industries,
          followersStats: {
            count: data.totalFollowers,
            showFollowButton: data.loggedInUserId != user._id,
            followed: data.userFollowing,
          },
          followingStats: {
            count: data.totalFollowings,
            showFollowButton: false,
          },
          reviews: data.reviews || [],
          reviewsCount:
            user.ratingTotal > 0 ? (user.ratingTotal / user.ratingNumber).toFixed(0) : 0,
          reviewExist: data.reviewExist,
        };
      } catch (e) {
        this.profileError = e.message;
        this.isLoading = false;
        console.log(e);
        //this.$router.push("/");
      }
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
.publicProfilePage {
  margin-top: 90px;
}
</style>
