<template>
  <div>
    <b-card title="Investor Company Description">{{ investor.companyDescription }}</b-card>
    <b-card>
      <div class="border-bottom pb-1">
        <strong>Interested in investment to</strong><br />
        {{
          this.$i18n.locale == "ar"
            ? investor.investorCategory.arabicName
            : investor.investorCategory.name
        }}
      </div>
      <div class="py-1 border-bottom">
        <strong>Business Factors:</strong><br />
        {{ investor.businessFactors }}
      </div>
      <div class="pt-1">
        <strong>Investment Size:</strong><br />
        {{ investor.investmentSize.min }} - {{ investor.investmentSize.max }}
        {{ investor.investorCurrency.shortName }}
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardBody, BCardTitle, BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
  },
  props: {
    investor: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getRole() {},
  },
};
</script>
