<template>
  <div class="profileIdeas ecommerce-application">
    <h1>{{ $t("profile.Projects") }}</h1>
    <div v-if="loading" class="text-center font-lg">
      <b-spinner variant="primary" />
    </div>
    <div v-if="!loading && projects.length == 0" class="alert alert-warning p-2 text-center">
      {{ isMyProfile ? $t("profile.project_not_created_mine") : $t("profile.project_not_created") }}
    </div>
    <!-- <b-table :items="projects" :fields="[{ key: 'name' }]"></b-table> -->
    <section class="grid-view" v-if="!loading">
      <b-card
        class="ecommerce-card"
        :key="index"
        no-body
        id="postWidth"
        v-for="(project, index) in projects"
      >
        <b-card-body>
          <b-link :to="`/profile/details/${project._id}`">
            <b-img
              v-if="project.files.length"
              fluid
              class="card-img-top"
              :src="project.files[0].fileUrl"
            />
          </b-link>
          <h6 class="item-name">
            <b-link :to="`/profile/details/${project._id}`" class="text-body">
              {{ project.name }}
            </b-link>
            <b-card-text class="item-company">
              <b-link class="ml-25">
                {{ project.saleType }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ project.description }}
          </b-card-text>
          <div class="item-wrapper">
            <div>
              <h6 v-if="project.price" class="item-price">${{ project.price.priceNumber }}</h6>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </section>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BLink,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import useProfile from "./useProfile.js";
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BImg,
  },
  data() {
    return {
      loading: false,
      projects: [],
    };
  },
  setup(props, { emit }) {
    const { getProfiles } = useProfile();

    return {
      getProfiles,
    };
  },
  async mounted() {
    this.loading = true;
    this.getProfiles(this.userId, "project").then((projects) => {
      this.projects = projects;
      this.loading = false;
    });
  },
  methods: {},
  props: {
    userId: {
      type: String,
    },
    isMyProfile: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
