<template>
  <div>
    <b-card no-body>
      <b-tabs card class="p-0">
        <b-tab :title="$t('profile.Experience')" active no-body>
          <b-table responsive :fields="brokerExperienceCols" :items="broker.experiences">
            <template #cell(location)="data">{{ data.item.location.name }}</template>
          </b-table>
        </b-tab>
        <b-tab :title="$t('profile.Previous Work')"
          ><b-card-text>{{ broker.previousWork }}</b-card-text></b-tab
        >
        <b-tab :title="$t('profile.Languages')" no-body
          ><b-table responsive :fields="brokerLanguageCols" :items="broker.languages"
        /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { BRow, BCol, BCard, BCardBody, BTabs, BTab, BTable, BCardText } from "bootstrap-vue";
import useProfile from "./useProfile";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BTab,
    BTabs,
    BTable,
  },
  props: {
    broker: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup() {
    const { brokerExperienceCols, brokerLanguageCols } = useProfile();

    return {
      brokerExperienceCols,
      brokerLanguageCols,
    };
  },
};
</script>
<style>
#profile-info .card .card-header {
  padding: 0px;
}
#profile-info .nav-tabs {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.nav-tabs .nav-item {
  border-right: 1px solid #f1f1f1;
}
#profile-info .nav-tabs .nav-link {
  padding: 1rem 1.5rem;
}
.table-responsive {
  margin-bottom: 0px;
}
</style>
