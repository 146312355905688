<template>
  <div id="recommendations">
    <h3 class="mb-2"><feather-icon icon="ShareIcon" /> Recommendations</h3>
    <b-row>
      <b-col v-for="(rec, index) in recommendations" :key="`rec${index}`" md="12">
        <b-card class="mb-1">
          <b-media>
            <template #aside v-if="rec._id.files.length">
              <b-link target="_blank" :href="`/profile/details/${rec._id._id}`"
                ><b-img
                  class="rounded"
                  width="100"
                  :src="getProfileTypeCoverImage(rec._id.files).fileUrl"
              /></b-link>
            </template>
            <b-link :href="`/profile/details/${rec._id._id}`"
              ><h5 class="mt-0 mb-0">{{ rec._id.name }}</h5></b-link
            >
            <div>
              <b-link
                target="_blank"
                :href="`/profile/${rec._id.userId.username}`"
                class="text-muted"
                >{{ rec._id.userId.username }}</b-link
              >
              •
              <span class="text-muted">{{
                $t(`Timeline.${capatalized(rec._id.profileType)}`)
              }}</span>
            </div>
            <p class="mb-0 text-muted">{{ rec._id.highlights }}</p>
          </b-media>
        </b-card>
      </b-col>
      <b-col md="12">
        <div v-if="isLoading" class="text-center text-muted">{{ $t("Timeline.Loading") }}</div>
        <b-button
          block
          variant="link"
          size="lg"
          v-if="loadedRecords < totalRecords && !isLoading"
          @click="loadRecommendations()"
          >{{ $t("Timeline.Load more") }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import useHelper from "@/helper";
import { BCard, BMedia, BLink, BImg, BRow, BCol, BButton } from "bootstrap-vue";
export default {
  name: "ProfileRecommendations",
  components: {
    BCard,
    BMedia,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      totalRecords: 0,
      loadedRecords: 0,
      perPage: 10,
      currentPage: 1,
      recommendations: [],
      isLoading: false,
    };
  },
  methods: {
    loadRecommendations: async function () {
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("userId", this.userId);
        formData.append("perPage", this.perPage);
        formData.append("page", this.currentPage);
        const { data } = await axios.post("/sharedProfiles/list", formData);
        const sharedProfiles = data.data.sharedProfiles;
        this.recommendations = [...this.recommendations, ...sharedProfiles];
        this.totalRecords = data.total;
        this.loadedRecords += sharedProfiles.length;
        this.isLoading = false;
        this.currentPage += 1;
      } catch (err) {
        this.isLoading = false;
      }
    },
    capatalized: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  mounted() {
    this.loadRecommendations();
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { getProfileTypeCoverImage } = useHelper();
    return {
      getProfileTypeCoverImage,
    };
  },
};
</script>
