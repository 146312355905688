<template>
  <div class="profileIdeas ecommerce-application">
    <h1>{{ $t("profile.Ideas") }}</h1>
    <div v-if="loading" class="text-center font-lg">
      <b-spinner variant="primary" />
    </div>
    <div v-if="!loading && ideas.length == 0" class="alert alert-warning text-center p-2">
      {{ isMyProfile ? $t("profile.idea_not_created_mine") : $t("profile.idea_not_created") }}
    </div>
    <!-- <b-table :items="ideas" :fields="[{ key: 'name' }]"></b-table> -->
    <section class="grid-view" v-if="!loading">
      <b-card
        class="ecommerce-card"
        :key="index"
        no-body
        id="postWidth"
        v-for="(idea, index) in ideas"
      >
        <b-card-body>
          <b-link :to="`/profile/details/${idea._id}`">
            <b-img
              v-if="idea.files.length"
              fluid
              class="card-img-top"
              :src="idea.files[0].fileUrl"
            />
          </b-link>
          <h6 class="item-name">
            <b-link class="text-body" :to="`/profile/details/${idea._id}`">
              {{ idea.name }}
            </b-link>
            <b-card-text class="item-company">
              <b-link class="ml-25">
                {{ idea.saleType }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ idea.description }}
          </b-card-text>
          <div class="item-wrapper">
            <div>
              <h6 v-if="idea.price" class="item-price">${{ idea.price.priceNumber }}</h6>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </section>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BLink,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BCardText,
  BImg,
} from "bootstrap-vue";
import useProfile from "./useProfile.js";
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,

    BLink,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BImg,
  },
  data() {
    return {
      loading: false,
      ideas: [],
    };
  },
  setup(props, { emit }) {
    const { getProfiles } = useProfile();

    return {
      getProfiles,
    };
  },
  async mounted() {
    this.loading = true;
    this.getProfiles(this.userId, "idea").then((data) => {
      this.ideas = data;
      this.loading = false;
    });
  },
  methods: {},
  props: {
    userId: {
      type: String,
    },
    isMyProfile: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
